@import url('https://fonts.googleapis.com/css?family=PT+Sans:400,700');

header {
  position: relative;
  background-color: #222;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  box-sizing: border-box;
  box-shadow: 2px 2px 10px rgba(33, 33, 33, 0.4);
  padding: 20px 0;
}
header .container {
  position: relative;
  height: 100%;
  z-index: 2;
}
.header-overlay {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(34, 34, 34, 0.7);
  -webkit-transition: background-color 0.3s ease;
  -ms-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
}
header ul {
  list-style: none;
  z-index: 2;
}
header ul li {
  display: inline-block;
  font-size: 1.4rem;
  vertical-align: middle;
  margin-right: 15px;
}
header ul li i.fa {
  font-size: 2rem;
}
header ul li a:after {
  display: none;
}
header ul li:last-child {
  margin-right: 0;
}
header ul li:last-child a,
header ul li:last-child span {
  color: #b0afaf;
  font-weight: 400;
  transition: all 0.3s ease;
}
header ul li:last-child a:hover span {
  color: #fff !important;
}
header h1 {
  max-width: 430px;
  margin: 50px 0 40px;
}
header h1 em {
  font-style: normal;
}
header h1 em,
header h1 span {
  display: block;
  font-weight: 400;
  line-height: 2.4rem;
}
header h1 span {
  font-size: 1.6rem;
}
header a {
  display: inline-block;
  font-weight: 700;
}
header a:after {
  content: '';
  display: block;
  width: 30px;
  height: 2px;
  background-color: #fff;
  transition: all 0.3s ease;
}
header a.full-line:after,
header a:hover:after {
  width: 100%;
}
header .bottom-bar {
  margin-top: 60px;
}
@media (min-width: 1024px) {
  header {
    box-sizing: border-box;
    padding: 50px 30px;
    width: 460px;
    min-height: 100%;
    height: 100vh;
    float: left;
  }
  header .intro-text {
    position: absolute;
    top: calc(50% - 150px);
  }
  header .bottom-bar {
    margin-top: 0;
    position: absolute;
    bottom: 20px;
  }
}
@media (min-width: 1200px) {
  header {
    padding: 50px;
    width: 550px;
  }
}
main {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  overflow: visible;
}
.projects-container {
  background-color: #333;
  box-sizing: border-box;
  padding: 20px 0;
  overflow-x: hidden;
  overflow-y: scroll;
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-o-keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.top-bar {
  margin-bottom: 30px;
}
.top-bar a {
  display: block;
  color: #b0afaf;
  cursor: pointer;
  transition: all 0.3s ease;
}
.top-bar a.active,
.top-bar a:hover {
  color: #fff;
}
.top-bar.back {
  position: relative;
}
/* .top-bar.back:hover i, */
.top-bar.back:hover span {
  color: #fff;
}
.top-bar.back i {
  opacity: 0;
}

.route-section.toLeftTransition-enter-done .top-bar.back i,
.route-section.toLeftTransition-enter-active .top-bar.back i {
  position: absolute;
  top: -4px;
  font-size: 2.8rem;
  vertical-align: middle;
  opacity: 1;
  -webkit-transition: all 0.3s 0.6s ease;
  transition: all 0.3s 0.6s ease;
}
.top-bar.back i,
.top-bar.back span {
  color: #b0afaf;
  transition: color 0.3s ease;
}
.top-bar.back span {
  padding-left: 20px;
}
.top-bar-wrapper {
  padding: 30px 0;
  background-color: #222;
}
.projects-list section {
  position: relative;
  display: block;
  max-width: 550px;
  height: 280px;
  overflow: hidden;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #222;
  margin-bottom: 20px;
  border-radius: 5px;
}
.projects-list section:hover:before {
  background-color: rgba(34, 34, 34, 0.3);
}
.projects-list section:hover:after {
  bottom: -30px;
}
.projects-list section:before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.projects-list section:after,
.projects-list section:before {
  content: '';
  display: block;
  position: absolute;
  background-color: rgba(34, 34, 34, 0.7);
  transition: all 0.3s ease;
  z-index: 2;
}
.projects-list section:after {
  bottom: -70px;
  left: -50%;
  width: 200%;
  height: 100px;
  transform: rotate(4deg);
}

.projects-list section .content {
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
  z-index: 3;
}
.projects-list section .content .external-link {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  font-size: 18px;
  text-align: center;
}
.projects-list section .content .box {
  position: absolute;
  left: 10px;
  bottom: 10px;
  box-sizing: border-box;
  line-height: 24px;
}
.projects-list section .content h3 {
  position: relative;
  display: block;
  margin-bottom: 0;
}
.projects-list section .content ul {
  position: relative;
  display: block;
}
.projects-list section .content ul li {
  display: inline-block;
  margin-right: 3px;
}
.projects-list section .content ul li:last-child {
  margin-right: 0;
}
.projects-list section .content ul li a {
  font-size: 1.4rem;
}
.projects-list section .content > a {
  display: none;
}
.projects-info {
  max-width: 610px;
  height: 100%;
}
.desc-section {
  position: relative;
  padding-bottom: 20px;
}
.desc-section p {
  margin-bottom: 0;
}
.desc-section p span {
  display: block;
  color: #b0afaf;
}
.desc-promo {
  display: block;
  width: 100%;
  height: 200px;
  overflow: hidden;
  background-color: #333;
  margin-bottom: 20px;
  border-radius: 5px;
}
.desc-promo.web {
  height: auto;
}
.desc-promo img {
  width: 100%;
  height: auto;
  border-radius: 5px;
}
.desc-promo.app {
  height: 400px;
  overflow: visible;
}
.desc-promo.app img {
  display: block;
  width: auto;
  height: 400px;
  margin: 0px auto;
  transform: scale(1.15);
  margin-left: -15px;
}
.desc-promo.app img.iphone7 {
  transform: scale(1.05);
  margin-left: -10px;
}
.desc-promo.app.no-transfrom,
.desc-promo.app.no-transfrom img {
  transform: none !important;
}
.desc-promo.app.full {
  min-width: 100%;
  max-width: 100%;
  height: auto;
  min-height: auto;
  max-height: auto;
  margin-bottom: 0;
  padding-bottom: 0;
}
.desc-promo.app.horizontal {
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
  height: 225px;
  min-height: auto;
  max-height: 225px;
}
.desc-promo.app.horizontal img {
  width: 100%;
  height: auto;
  min-height: auto;
  margin: 0px auto;
}

.desc-promo-background {
  position: relative;
  display: block;
  width: calc(100% + 40px);
  height: 400px;
  background-color: #222;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-left: -20px;
  overflow: hidden;
  transition: all 0.3s ease;
}
.desc-promo-background:before {
  content: '';
  display: block;
  position: absolute;
  top: -80px;
  left: -40%;
  width: 150%;
  height: 100px;
  -webkit-transform: rotate(4deg);
  -ms-transform: rotate(4deg);
  transform: rotate(4deg);
  background-color: #333;
}
.desc-promo-background:after {
  content: '';
  display: block;
  position: absolute;
  bottom: -80px;
  left: -40%;
  width: 150%;
  height: 100px;
  -webkit-transform: rotate(-4deg);
  -ms-transform: rotate(-4deg);
  transform: rotate(-4deg);
  background-color: #333;
}
.desc-promo-background.app {
  min-height: auto;
  height: auto;
}
.desc-promo-background.grid {
  width: 100%;
  height: auto;
  margin-left: 0;
  margin-bottom: 20px;
  background-color: transparent;
}
.desc-promo-background.grid::before,
.desc-promo-background.grid::after {
  display: none;
}

.desc-promo-background.grid img {
  width: 100%;
}

.desc-promo-background.about {
  min-height: 350px;
  max-height: 350px;
  opacity: 0.4;
  transition: all 0.4s ease;
}
.desc-promo-background.about:hover {
  opacity: 1;
}
.desc-promo-background.inverted:before {
  content: '';
  display: block;
  position: absolute;
  top: -50px;
  left: -40%;
  width: 150%;
  height: 100px;
  -webkit-transform: rotate(-4deg);
  -ms-transform: rotate(-4deg);
  transform: rotate(-4deg);
  background-color: #333;
}
.desc-promo-background.inverted:after {
  content: '';
  display: block;
  position: absolute;
  bottom: -40px;
  left: -50%;
  width: 150%;
  height: 100px;
  -webkit-transform: rotate(4deg);
  -ms-transform: rotate(4deg);
  transform: rotate(4deg);
  background-color: #333;
}

.projects-info.fun-park .desc-promo.app {
  margin: 20px 0;
}

span.wave {
  animation-name: wave-animation;
  animation-delay: 1s;
  animation-duration: 2.5s;
  animation-iteration-count: 1;
  transform-origin: 70% 70%;
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(-10deg);
  }
  20% {
    transform: rotate(12deg);
  }
  30% {
    transform: rotate(-10deg);
  }
  40% {
    transform: rotate(9deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

ul.desc-timeline {
  position: relative;
  display: block;
  padding: 20px;
  margin: 0 0 20px 0;
  list-style: none;
}
ul.desc-timeline::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 100%;
  background-color: rgb(224, 149, 89);
}
ul.desc-timeline li {
  padding-left: 10px;
  border-radius: 20px;
  margin: 10px 0 12px 0;
  transition: all 0.2s ease;
}
ul.desc-timeline li:hover {
  background-color: rgba(34, 34, 34, 0.3);
}
ul.desc-timeline li:hover > b {
  margin-left: -12px;
  padding: 8px 16px;
}
ul.desc-timeline li b {
  background-color: rgb(224, 149, 89);
  padding: 5px 12px;
  border-radius: 12px;
  transition: all 0.2s ease;
  cursor: default;
}
ul.desc-timeline li p {
  display: block;
  /* padding-left: 10px; */
  padding: 12px 4px 20px 4px;
}

.contact-section {
  position: relative;
  display: block;
  width: 100%;
  padding: 20px 0;
  float: left;
}
.contact-section::before,
.contact-section::after {
  content: '';
  display: block;
  clear: both;
}

.contact-section h3 {
  margin: 30px 0 0 0;
  line-height: 2.8rem;
}

blockquote {
  margin: 30px 0 0 0;
  padding: 0;
  background: none;
  color: #eee;
}
blockquote a {
  position: relative;
  color: #eee;
  transition: all 0.3s ease;
}
blockquote a:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 0%;
  height: 1px;
  background: #fff;
  transition: all 0.3s ease;
}
blockquote a:hover {
  color: #fff;
}
/* blockquote a:hover:after {
  width: 100%;
} */

.spinner-wrapper {
  position: relative;
  display: block;
  max-width: 550px;
  height: 280px;
  overflow: hidden;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: transparent;
  margin-bottom: 20px;
  border-radius: 5px;
}
.spinner {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -15px;
  margin-left: -15px;
  border: 3px solid #b0afaf;
  border-radius: 50%;
}
.spinner:before,
.spinner:after {
  content: '';
  position: absolute;
  display: block;
  width: 3px;
  background-color: #b0afaf;
  border-radius: 1.5px;
  -webkit-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
}
.spinner:before {
  height: 9px;
  left: 13.5px;
  top: 50%;
  -webkit-animation: spin 2000ms linear infinite;
  animation: spin 2000ms linear infinite;
}
.spinner:after {
  height: 12px;
  left: 13.5px;
  top: 50%;
  -webkit-animation: spin 500ms linear infinite;
  animation: spin 500ms linear infinite;
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media (min-width: 768px) {
  .projects-container {
    padding: 30px 0;
  }
  .top-bar a {
    display: inline-block;
    margin-right: 10px;
  }
  .projects-list section .content .box {
    left: 20px;
    bottom: 15px;
    line-height: 26px;
  }
  .projects-list section .content > a {
    position: absolute;
    display: block;
    bottom: 18px;
    right: 20px;
    font-size: 1.2rem;
  }
  .desc-promo {
    height: 300px;
  }
  .desc-promo.web {
    height: auto;
    max-height: 330px;
  }
  .desc-promo.app {
    width: 50%;
    float: left;
    height: 500px;
  }
  .desc-promo.app img {
    display: block;
    width: auto;
    height: 480px;
    margin: 20px auto;
  }
  .desc-promo.app img.iphone7 {
    margin-left: -15px;
    margin-top: 5px;
  }
  .desc-promo.app:after {
    content: '';
    display: block;
    clear: both;
  }
}
@media (min-width: 1024px) {
  main {
    width: calc(100% - 460px);
    min-height: 100%;
    height: 100vh;
    float: left;
  }
  main:after {
    content: '';
    display: block;
    clear: both;
  }
  .projects-container {
    box-sizing: border-box;
    padding: 50px 20px;
    z-index: -9;
  }
  .top-bar.back {
    margin-left: -25px;
  }
  .top-bar.back i {
    position: fixed;
    top: 46px;
  }
  .top-bar.back span {
    padding-left: 25px;
  }
  .project-decor-top {
    position: absolute;
    top: -500px;
    right: -1500px;
    display: block;
    width: 3000px;
    height: 400px;
    background-color: #252525;
    transition: all 0.3s ease;
  }
  .project-decor-top.active {
    top: -350px;
    transform: rotate(-4deg);
    transition: all 0.3s 0.6s ease;
  }
  section.route-section {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }
  .desc-section {
    padding-bottom: 30px;
  }
  .desc-section.first {
    margin-top: 60px;
  }
  .desc-section.app p {
    margin-bottom: 20px;
  }
  .desc-promo {
    margin-bottom: 30px;
  }
  .desc-promo.app img.iphone7 {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.15);
    margin-left: 0;
    margin-top: 5px;
  }
  .desc-promo-background {
    width: calc(100% + 80px);
    height: 500px;
    margin-left: -40px;
  }
  .desc-promo-background.grid {
    width: 100%;
    height: auto;
    margin-left: 0;
  }
  .desc-promo-background.grid img {
    width: 100%;
  }
  .projects-info.fun-park .desc-promo.app {
    margin: 0 0 20px 0;
  }
  .projects-info.fun-park .desc-promo.app.full {
    margin: 15px 0 0 0;
  }
  .desc-promo.app.horizontal {
    padding: 0 20px 0 20px;
  }
  .contact-section {
    position: relative;
  }
  .contact-section hr {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #222;
    border: none;
    z-index: 9;
  }
  .contact-section h3 {
    margin: 30px 0 0 0;
    line-height: 2.8rem;
  }
  blockquote {
    padding: 0 0 40px 0;
    border-bottom: 1px solid #eee;
  }
  blockquote.app {
    clear: both;
    padding-top: 10px;
  }
}
@media (min-width: 1200px) {
  .projects-container {
    padding: 50px;
  }
  .projects-list section {
    height: 395px;
    margin-bottom: 40px;
  }
  .projects-list section:after {
    height: 110px;
  }
  main {
    width: calc(100% - 550px);
  }
  .top-bar.back {
    margin-left: -40px;
  }
  .top-bar.back span {
    padding-left: 40px;
  }
  .desc-promo-background {
    width: calc(100% + 140px);
    min-height: 700px;
    height: 100vh;
    margin-left: -70px;
  }
  .desc-promo-background.grid {
    width: 100%;
    height: auto;
    min-height: auto;
    margin-left: 0;
    margin-bottom: 30px;
  }
  .spinner-wrapper {
    height: 395px;
    margin-bottom: 40px;
  }
}

/* Left */
.toLeftTransition-enter {
  max-height: 0px;
  overflow: hidden;
  opacity: 0.01;
  transform: translateX(150%);
}
.toLeftTransition-enter.toLeftTransition-enter-active {
  max-height: 100vh;
  opacity: 1;
  transform: translateX(0%);
  transition: all 0.3s 0.3s ease-in;
}
.toLeftTransition-exit {
  opacity: 1;
  transform: translateX(0%);
}

.toLeftTransition-exit.toLeftTransition-exit-active {
  opacity: 0.01;
  transform: translateX(-150%);
  transition: all 0.3s ease-in;
}

/* Top */
.toTopTransition-enter {
  opacity: 0.01;
  transform: translateY(400px);
}
.toTopTransition-enter.toTopTransition-enter-active {
  opacity: 1;
  transform: translateY(0px);
  transition: all 0.3s ease-in;
}
.toTopTransition-exit {
  opacity: 1;
  transform: translateY(0px);
}

.toTopTransition-exit.toTopTransition-exit-active {
  opacity: 0.01;
  transform: translateY(0px);
  /* transition: all 0.3s ease-in; */
}

div.transition-group {
  position: relative;
}
section.route-section {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
}

@media (min-width: 1440px) {
  header {
    padding: 100px;
    width: 700px;
  }
  main {
    width: calc(100% - 700px);
  }
  section.route-section {
    max-width: 700px;
    margin: 0 auto;
  }
  .projects-list section {
    max-width: 700px;
    height: 450px;
  }
  .projects-info {
    max-width: 700px;
  }
  .desc-promo.web {
    max-height: 430px;
  }
  .desc-promo-background {
    width: calc(100% + 280px);
    margin-left: -140px;
  }
}
